import Header from '../components/header/header'
import HeaderImage from '../images/ou-flowers.jpg'
// import CollegeOU from '../../public/Sponsors/OU-BioMed.jpeg'
import Diamond from '../images/Diamond.png'
import './sponsor.css'
import '../App.css';
import React from "react";

const Sponsor = () => {
    return (
        <>
            <header>
                <Header text="Thank You to Our Sponsors" tag="sponsorship" backgroundImage={HeaderImage}/>
                <div className="thankyou">
                    <h5>
                        This event wouldn't be possible without you!
                    </h5>
                    <p>
                        We extend our deepest gratitude to all our current
                        and future sponsors for their invaluable contributions. <br/>
                        Your generosity and commitment will help shape the
                        skills and potential of tomorrow's Biomedical Engineers.
                    </p>
                </div>
            </header>
            <body>
            <h2>2025 Sponsors</h2>
            {/*    DIAMOND LEVEL SPONSOR    */}
            <div id="feature-glow">
                <img src={Diamond} alt={"Diamond Sponsor"} className="feature"/>
            </div>
            {/*    ALL SPONSORS    */}
            <div className="sponsors">
                <img src={require("../images/ou-bme.png")} alt={"sponsor"}/>
                <img src={require("../images/logoplaceholder.png")} alt={"logo place holder"} id="placeholder"/>
            </div>

            {/*    Gold Tier     Silver Tier      Bronze Tier    */}
            <h3>
                Join Us, Become a Sponsor!
            </h3>
            <div className="sponsor-tiers">
                <div className="boarder glow" id="diamond">
                    <div className="tier">
                        <h4>DIAMOND</h4>
                        <p class="price">$10,000</p>
                        <ul>
                            <li><span>Choose location at Career Fair</span></li>
                            <li><span>Biggest Company Logo on 2025 MDM Website & T-Shirts</span></li>
                            <li><span>Company Information Gets Sent Out to Each Participant</span></li>
                            <li><span>Access to the 200 participant Resume Bank</span></li>
                            <li><span>Choose the Clinical Issue</span></li>
                        </ul>
                    </div>
                </div>
                <div className="boarder" id="gold">
                    <div className="tier">
                        <h4>GOLD</h4>
                        <p className="price">$5000</p>
                        <ul>
                            <li><span>Table at Career Fair</span></li>
                            <li><span>Company Logo on 2025 MDM Website & T-Shirts</span></li>
                            <li><span>Company Information Gets Sent Out to Each Participant</span></li>
                            <li><span>Access to the 200 participant Resume Bank</span></li>
                        </ul>
                    </div>
                </div>
                <div className="boarder" id="silver">
                    <div className="tier">
                        <h4>SILVER</h4>
                        <p className="price">$2500</p>
                        <ul>
                            <li><span>Table at Career Fair</span></li>
                            <li><span>Company Logo on 2025 MDM Website & T-Shirts</span></li>
                            <li><span>Company Information Gets Sent Out to Each Participant</span></li>
                        </ul>
                    </div>
                </div>
                <div className="boarder" id="bronze">
                    <div className="tier">
                        <h4>BRONZE</h4>
                        <p className="price">$1000</p>
                        <ul>
                            <li><span>Table at career fair</span></li>
                        </ul>
                    </div>
                </div>
            </div>
            </body>
        </>
    );
};
export default Sponsor;