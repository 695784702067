import { Link, useMatch, useResolvedPath} from "react-router-dom";
// import Logo from "./MDMwhitelogo.png";

const Navbar = () => {
    return (
        <nav className="nav">
            <Link to="/" className="site-name">
                {/*MDM*/}
                <img src="/mdmicon.png" alt="MDM" className="logo-image" />
                {/*<link rel="icon" href="%PUBLIC_URL%/mdmicon.png" />*/}
            </Link>
            <ul>
                <CustomLink to="/sponsor">Sponsorship</CustomLink>
                <CustomLink to="/history">Past Competitions</CustomLink>
                <CustomLink to="/team">Meet Our Team</CustomLink>
                <CustomLink to="/contact">Contact</CustomLink>
            </ul>
        </nav>
    )
}
export default Navbar;

function CustomLink({ to, children, ...props}) {
    const resolvedPath = useResolvedPath(to)
    const isActive = useMatch({path: resolvedPath.pathname, end: true})

    return (
        <li className={isActive ? "active" : ""}>
            <Link to={to} {...props}>
                {children}
            </Link>
        </li>
    )
}
