import React from "react";
import {Route, Routes} from "react-router-dom"
import Navbar from "./components/navigation/navbar";
import Home from "./pages/Home";
import Sponsor from "./pages/Sponsor";
import History from "./pages/History";
import Team from "./pages/Team";
import Contact from "./pages/Contact";
import './App.css';

const App = () => {

    return (
        <>
            <Navbar />
            <div className="container">
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/sponsor" element={<Sponsor />} />
                    <Route path="/history" element={<History />} />
                    <Route path="/team" element={<Team />} />
                    <Route path="/contact" element={<Contact />} />
                </Routes>
            </div>
            <h3>______________________________________________________</h3>
            <br/>
            <h3> Please excuse the mess, our new site is still under construction :) </h3>
            <br/>
        </>
    )
}

export default App;
