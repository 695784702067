import React from "react";
import Header from "../components/header/header";
import HomeImage from '../images/ouhome.jpeg';    /* rename image when changed : see tip ## */
import Logo from '../images/MDM-Logo.png';
import '../App.css';
import '../pages/home.css';

const Home = () => {
    return (
        <>
            <header>
                <span className="welcome">Join us February 28 - March 2, 2025 for the 7th annual</span>
                <Header text="MEDICAL DEVICE" text2="MAKE-A-THON" tag="mdm" backgroundImage={HomeImage} />
            </header>
            <body>
            <div className="about">
                <div>
                    <img src={Logo} alt={"MDM 2025 Logo"} />
                </div>
                <div>
                    <h1> About Us </h1>
                    <p>
                        The Medical Device Make-A-Thon is a 48-hour competition where teams are challenged to
                        design and prototype a medical device that solves a given clinical or medical problem.
                    </p>
                    <p>
                        Students from all over the country and various backgrounds of majors are invited to
                        participate in this fun filled weekend of networking and innovation.
                    </p>
                </div>
                <div>
                    <img src={require("../images/48dark.png")} alt={"48 hours"}/>
                </div>
            </div>


            <h1> When & Where </h1>
            <h3> February 28th through March 2nd, 2025</h3>
            <h3> Hosted by the University of Oklahoma </h3>

            <h1> Join Us! </h1>
            <p>
                Students will learn valuable skills that will aid them in developing a prototype of their device.
                A Solidworks workshop will be held to help students design a 3D model of their device
                and they will meet with experts in the field who will mentor them. After designing this model,
                the top 15 teams will move on to the next round of judging and they will be able to 3D print their
                devices and submit a final presentation.
            </p>
            <p>
                The judges will be industry professionals and university professors with
                whom the students will have plenty of opportunities to network with.
            </p>
            <p> Top 5 teams will receive prize money! </p>

            <h2> Registration opens <br/> October 7th @ 8:00am! </h2>
            </body>
        </>
    );
};
export default Home;